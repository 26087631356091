@charset "UTF-8";

/* crimson-text-regular - latin */
@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/crimson-text-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Crimson Text Regular'), local('CrimsonText-Regular'),
       url('../fonts/crimson-text-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/crimson-text-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/crimson-text-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/crimson-text-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/crimson-text-v10-latin-regular.svg#CrimsonText') format('svg'); /* Legacy iOS */
}
/* crimson-text-700 - latin */
@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/crimson-text-v10-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Crimson Text Bold'), local('CrimsonText-Bold'),
       url('../fonts/crimson-text-v10-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/crimson-text-v10-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/crimson-text-v10-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/crimson-text-v10-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/crimson-text-v10-latin-700.svg#CrimsonText') format('svg'); /* Legacy iOS */
}

.note.wrapper.style1 {
	margin:-110px 0 0;
	padding:70px 30px 100px;
	text-align:left;
	font-size:22px;
}

.note.wrapper.style1 .title {
	color:#d81414;
}

.note ol,
.privacy ul {
	list-style-type:circle;
    margin-left:18px;
    margin-top:20px;
    margin-bottom:20px;
}

.privacy h2 {
  font-size:20px;
  margin:30px 0 10px;
}

.privacy h3 {
  margin:20px 0 10px;
}

.privacy p {
  margin-bottom:15px;
}